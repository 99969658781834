// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-docs-index-mdx": () => import("./../../../src/pages/docs/index.mdx" /* webpackChunkName: "component---src-pages-docs-index-mdx" */),
  "component---src-pages-docs-nice-60-getting-started-mdx": () => import("./../../../src/pages/docs/nice-60/getting-started.mdx" /* webpackChunkName: "component---src-pages-docs-nice-60-getting-started-mdx" */),
  "component---src-pages-docs-nice-60-index-mdx": () => import("./../../../src/pages/docs/nice-60/index.mdx" /* webpackChunkName: "component---src-pages-docs-nice-60-index-mdx" */),
  "component---src-pages-docs-nice-60-schematic-mdx": () => import("./../../../src/pages/docs/nice-60/schematic.mdx" /* webpackChunkName: "component---src-pages-docs-nice-60-schematic-mdx" */),
  "component---src-pages-docs-nice-nano-faq-mdx": () => import("./../../../src/pages/docs/nice-nano/faq.mdx" /* webpackChunkName: "component---src-pages-docs-nice-nano-faq-mdx" */),
  "component---src-pages-docs-nice-nano-getting-started-mdx": () => import("./../../../src/pages/docs/nice-nano/getting-started.mdx" /* webpackChunkName: "component---src-pages-docs-nice-nano-getting-started-mdx" */),
  "component---src-pages-docs-nice-nano-index-mdx": () => import("./../../../src/pages/docs/nice-nano/index.mdx" /* webpackChunkName: "component---src-pages-docs-nice-nano-index-mdx" */),
  "component---src-pages-docs-nice-nano-pinout-schematic-mdx": () => import("./../../../src/pages/docs/nice-nano/pinout-schematic.mdx" /* webpackChunkName: "component---src-pages-docs-nice-nano-pinout-schematic-mdx" */),
  "component---src-pages-docs-nice-nano-troubleshooting-mdx": () => import("./../../../src/pages/docs/nice-nano/troubleshooting.mdx" /* webpackChunkName: "component---src-pages-docs-nice-nano-troubleshooting-mdx" */),
  "component---src-pages-docs-nice-view-getting-started-mdx": () => import("./../../../src/pages/docs/nice-view/getting-started.mdx" /* webpackChunkName: "component---src-pages-docs-nice-view-getting-started-mdx" */),
  "component---src-pages-docs-nice-view-index-mdx": () => import("./../../../src/pages/docs/nice-view/index.mdx" /* webpackChunkName: "component---src-pages-docs-nice-view-index-mdx" */),
  "component---src-pages-docs-nice-view-pinout-schematic-mdx": () => import("./../../../src/pages/docs/nice-view/pinout-schematic.mdx" /* webpackChunkName: "component---src-pages-docs-nice-view-pinout-schematic-mdx" */),
  "component---src-pages-docs-wireless-firmware-bluemicro-mdx": () => import("./../../../src/pages/docs/wireless-firmware/bluemicro.mdx" /* webpackChunkName: "component---src-pages-docs-wireless-firmware-bluemicro-mdx" */),
  "component---src-pages-docs-wireless-firmware-index-mdx": () => import("./../../../src/pages/docs/wireless-firmware/index.mdx" /* webpackChunkName: "component---src-pages-docs-wireless-firmware-index-mdx" */),
  "component---src-pages-docs-wireless-firmware-zmk-mdx": () => import("./../../../src/pages/docs/wireless-firmware/zmk.mdx" /* webpackChunkName: "component---src-pages-docs-wireless-firmware-zmk-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nice-60-tsx": () => import("./../../../src/pages/nice-60.tsx" /* webpackChunkName: "component---src-pages-nice-60-tsx" */),
  "component---src-pages-nice-nano-tsx": () => import("./../../../src/pages/nice-nano.tsx" /* webpackChunkName: "component---src-pages-nice-nano-tsx" */),
  "component---src-pages-nice-view-tsx": () => import("./../../../src/pages/nice-view.tsx" /* webpackChunkName: "component---src-pages-nice-view-tsx" */)
}

