module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WEV7JRSTJ5"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nice Keyboards","short_name":"Nice Keyboards","start_url":"/","background_color":"#1A202C","theme_color":"#0BC5EA","display":"browser","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"841cca3ec459e8ccb560d1cb6530b006"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images-medium-zoom","id":"e0984a41-d10e-5635-a79c-2fc42f2aaa08","name":"gatsby-remark-images-medium-zoom","version":"1.7.0","pluginOptions":{"plugins":[],"background":"rgba(26, 32, 44, 0.7)"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"default":"/opt/build/repo/src/components/docs-layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":650,"backgroundColor":"transparent","disableBgImageOnAlpha":true,"linkImagesToOriginal":false}},"gatsby-remark-images-medium-zoom",{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[],"background":"rgba(26, 32, 44, 0.7)"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
